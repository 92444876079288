<template>
  <div>
    <Bar
      :chart-data="chartData"
      :chart-options="chartOptions"
      :height="height"
      :styles="styles"
      :width="width"
    />
  </div>
</template>

<script>
// import { Line as BarChartGenerator } from "vue-chartjs/legacy";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 340,
    },
    height: {
      type: Number,
      default: 340,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
            },
          },
        };
      },
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: ["JAN/23", "FEV/23", "MAR/23", "ABR/23"],
          datasets: [
            { data: [40, 20, 12, 40], label: "", backgroundColor: "#f87979" },
            { data: [40, 20, 12, 40], label: "", backgroundColor: "#f6f6" },
          ],
        };
      },
    },
  },
  data() {
    return {
      // chartData: {
      //   labels: ["JAN/23", "FEV/23", "MAR/23", "ABR/23"],
      //   datasets: [
      //     { data: [40, 20, 12, 40], label: "", backgroundColor: "#f87979" },
      //     { data: [40, 20, 12, 40], label: "", backgroundColor: "#f6f6" },
      //   ],
      // },
      // chartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   scales: {
      //     x: {
      //       stacked: true,
      //     },
      //     y: {
      //       stacked: true,
      //     },
      //   },
      // },
    };
  },
};
</script>

<style lang="scss" scoped></style>
