var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass:
        "mx-2 my-2 py-2 info-card d-flex align-center justify-center",
      style: { borderLeft: `5px solid ${_vm.borderColorProp}` },
      attrs: { elevation: "2", shaped: "" },
    },
    [
      _c("section", { staticClass: "info-card__content" }, [
        _c("div", { staticClass: "info-card__content__left" }, [
          _c(
            "div",
            {
              staticClass: "icon",
              style: { backgroundColor: _vm.mainColorProp },
            },
            [
              _c(
                "v-icon",
                {
                  staticStyle: {
                    display: "block",
                    "text-align": "center",
                    margin: "0px !important",
                    "margin-top": "10px !important",
                  },
                  attrs: { color: "white" },
                },
                [_vm._v(_vm._s(_vm.icon))]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "info-card__content__right" },
          [
            _c("v-row", { staticClass: "mb-1", attrs: { justify: "center" } }, [
              _c(
                "span",
                {
                  staticStyle: { "font-size": "0.69rem", "font-weight": "700" },
                },
                [
                  _vm._t("title", function () {
                    return [_vm._v("Título")]
                  }),
                ],
                2
              ),
            ]),
            _c("v-row", { staticClass: "mt-0", attrs: { justify: "center" } }, [
              _c(
                "span",
                {
                  style: {
                    color: _vm.mainColorProp,
                    textAlign: "center",
                    fontSize: "1.15rem",
                  },
                },
                [
                  _vm._t("value", function () {
                    return [_vm._v("0")]
                  }),
                ],
                2
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "dot", staticStyle: { background: "none" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }