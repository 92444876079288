var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Bar", {
        attrs: {
          "chart-data": _vm.chartData,
          "chart-options": _vm.chartOptions,
          height: _vm.height,
          styles: _vm.styles,
          width: _vm.width,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }